<template>
    <div id="redirect" class="w-100 height-full">
        <div class="container d-flex flex-row justify-content-center align-items-center h-100 height-full">
            <span v-on:click="navigateToIntent(true)">
                <img :src=" getLogo( 'zzvendas' ) "
                     alt="ZZNet Logo"
                     style="width: 100%"
                />
                Abrir atendimento
            </span>
        </div>
    </div>
</template>

<style type="text/css">
html, body, main, #redirect
{
    height: 100%
}

#redirect span
{
    width: 100px;
    text-align: center;
}

#redirect span:hover
{
    cursor: pointer;
}
</style>

<script>

import {Utils} from "@/components/utils";

export default {
    name: 'redirect',
    mixins: [Utils],
    props: ['idAtendimento'],
    methods: {
        navigateToIntent(newWindow) {

            const idAtendimento = parseInt(this.getOnlyNumbers(this.idAtendimento));
            if (this.lodash.isNumber(idAtendimento)) {

                const urlIntent = "zznet://revendedora/atendimento/";

                if (newWindow) {
                    window.open(urlIntent.concat(idAtendimento));
                }
                else {
                    document.location.href = urlIntent.concat(idAtendimento);
                }

            }
        }
    },
    created() {
        document.title = 'Redirecionando...';
        this.navigateToIntent(false);
    }
}
</script>
